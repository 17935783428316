<template>

    <div id="mainContent" class="mt-8 sm:w-1/2 m-auto space-y-8 mb-8">
      <h1 class="text-4xl sm:text-5xl font-extrabold">Your guide to <span class="pride">pride</span>!</h1>
      <div class="mx-5 mt-3" id="pride">
        <h1 class="text-2xl sm:text-3xl font-extrabold">What is pride month?</h1>
        <p class="text-xl mt-2">June is Pride month - a month dedicated to celebrating LGBTQ+ communities all around the world. Pride month is about acceptance, equality, celebrating the work of LGBTQ+ people, education in LGBTQ+ history and raising awareness of issues affecting the LGBTQ+ community.</p>
      </div>
      <div class="mx-5 mt-3" id="pride">
        <h1 class="text-2xl sm:text-3xl font-extrabold">What is the importance behind pride month?</h1>
        <p class="text-xl mt-2">Pride month occurs to commemorate the Stonewall riots, which occurred between 28 June 1969 and 3 July 1969. As a result, many pride events are held during this month to recognize the impact that LGBTQ+ people have had in the world.</p>
      </div>
      <div class="mx-5 mt-2" id="lgbt">
        <h1 class="text-2xl sm:text-3xl font-extrabold">What does LGBTQ stand for?</h1>
        <p class="text-bold text-xl"><span class="text-red-600 font-bold">L</span>esbian <span class="text-orange-400 font-bold">G</span>ay <span class="text-green-500 font-bold">B</span>isexual <span class="text-blue-400 font-bold">T</span>ransgender <span class="text-purple-400 font-bold">Q</span>ueer</p>
      </div>
    </div>
    
</template>

<script>

export default {
  name: 'HomePage',
  components: {
  }
}
</script>
