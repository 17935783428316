<template>

    <div id="mainContent" class="mt-8 sm:w-1/2 m-auto space-y-8 mb-8">
      <h1 class="text-4xl sm:text-5xl font-extrabold">Helpful <span class="pride">LGBT</span> resources!</h1>
      <div class="mx-5 mt-3" id="pride">
        <h1 class="text-2xl sm:text-3xl font-extrabold">Here are some helpful resources we found:</h1>
        <div class="flex">
        
        </div>
      </div>
    </div>
    
</template>

<script>

export default {
  name: 'DonatePage',
  components: {
  }
}
</script>
